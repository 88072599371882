/* **************************************************
  Component Name   : Login Component
  Description : This component is used for login process
  Created By  : Bibhuti Bhusan Sahoo
  Created On  : 20Jun2022
 ******************************************* */

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LoginService } from './login.service';
import { environment } from '../../environments/environment';
import { NgbModal, NgbTooltip, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorchklistService } from 'src/app/validatorchklist.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { EncryptDecryptService } from 'src/app/encrypt-decrypt.service';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import * as e from 'cors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public fg: FormGroup;
  sourceType = sessionStorage.getItem('sourceType') ? sessionStorage.getItem('sourceType') : '';
  isVisible: any = this.sourceType == 'CCP' ? 1:'';
  isSelected: boolean = true;
  aadharNoInput: any = '';
  mobileNoInput: any = '';
  valid: boolean = false;
  timeLeft: number = 120;
  interval;
  public editable = false;
  loading = false;
  textOTP: any = '';
  enctext: any = '';
  result: any = '';
  maxAadharLenght = 12;
  minAadharLenght = 12;
  maxLghMob = 10;
  minLghMob = 10;
  fileUrl:any= environment.ccpHomeUrl;
  koWebsiteUrl:any = environment.koWebsiteUrl;
  //fileUrl:any= 'http://164.164.122.169:8060/CCP_STG/CCP/assets/images/';
  returnUrl: any = '';
  submitedEncStr: any = '';
  language: any = 'English';
  isDisabled: boolean = false;
  mobileNo:any = '';
  @ViewChild('someModal') someModalRef: ElementRef;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private modalService: NgbModal,
    public vldChkLst: ValidatorchklistService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private encDec: EncryptDecryptService,
    public translate: TranslateService

  ) {
    translate.addLangs(['English', 'Odia']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|Odia/) ? browserLang : 'English');
      $('body').addClass(browserLang);
    } else {
      localStorage.setItem('locale', 'English');
      translate.setDefaultLang('English');
    }
  }

  ngOnInit(): void {

    if (sessionStorage.getItem('FFS_SESSION') != '' && sessionStorage.getItem('FFS_SESSION') != null) {
      this.router.navigate(['/citizen-portal/dashboard']);
    }

    this.returnUrl = this.activateRoute.snapshot.params.returnUrl;
    this.activateRoute.queryParams.subscribe((params) => {
      this.returnUrl = params['returnUrl'];
      this.submitedEncStr = params['id'];
    });
    if(this.submitedEncStr != '' && this.submitedEncStr != undefined){
      let submitedText = this.encDec.decText(this.submitedEncStr);
      let submitedArr =  submitedText.split(':');
      let beforeLoginSelectedDistrict = (submitedArr[0] != undefined && submitedArr[0] != '') ? submitedArr[0] : 0;
      let beforeLoginSelectedBlock = (submitedArr[1] != undefined && submitedArr[1] != '') ? submitedArr[1] : 0;
      let beforeLoginSelectedBank = (submitedArr[2] != undefined && submitedArr[2] != '') ? submitedArr[2] : 0;
      let beforeLoginSelectedSector = (submitedArr[3] != undefined && submitedArr[3] != '') ? submitedArr[3] : 0;
      let beforeLoginSelectedLoantype = (submitedArr[4] != undefined && submitedArr[4] != '') ? submitedArr[4] : 0;
      let beforeLoginSelectedBankType = (submitedArr[5] != undefined && submitedArr[5] != '') ? submitedArr[5] : 0;
      let beforeLoginSelectedUrl = (submitedArr[6] != undefined && submitedArr[6] != '') ? submitedArr[6] : '';
      let beforeLoginSelectedOscb = (submitedArr[7] != undefined && submitedArr[7] != '') ? submitedArr[7] : 0;
      if(beforeLoginSelectedUrl == 'LoanProduct')
        this.returnUrl = beforeLoginSelectedUrl;
      sessionStorage.setItem('beforeLoginSelectedDistrict',beforeLoginSelectedDistrict);
      sessionStorage.setItem('beforeLoginSelectedBlock', beforeLoginSelectedBlock);
      sessionStorage.setItem('beforeLoginSelectedBank', beforeLoginSelectedBank);
      sessionStorage.setItem('beforeLoginSelectedSector', beforeLoginSelectedSector);
      sessionStorage.setItem('beforeLoginSelectedLoantype', beforeLoginSelectedLoantype);
      sessionStorage.setItem('beforeLoginSelectedOscb', beforeLoginSelectedOscb);
      if(beforeLoginSelectedLoantype==22 && beforeLoginSelectedBankType==1){
        this.isVisible = 2;
        this.isDisabled = true;}
    }
  }



  /**
   * Function name: g
   * description :  This method is to get all the form controls
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 20Jun2022
   */
  get g() {
    return this.fg.controls;
  }

  /**
   * Function name: startTimer
   * description :  This method for start the otp timer
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 20Jun2022
   */
  startTimer() {
    if(this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.editable = false;
      } else {
        this.timeLeft = 0;
        this.editable = true;
      }
    }, 1000)
  }

  /**
   * Function name: loginForm
   * description :  This method for login form
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 14Jun2022
   */
  loginForm() {
    let aadhaarnumber = this.aadharNoInput;
    let mobilenumber = this.mobileNoInput;
    let isVisible = this.isVisible;

    let vSts = true;
    if(isVisible == 2){
      if (!this.vldChkLst.blankCheck(aadhaarnumber, "Aadhaar Number")) {
        vSts = false;
      }
      else if (!this.vldChkLst.isNumeric(aadhaarnumber, "Only Numeric Value")) {
        vSts = false;
      }
      else if (!this.vldChkLst.maxLength(aadhaarnumber, this.maxAadharLenght, "Aadhaar Number")) {
        vSts = false;
      }
      else if (!this.vldChkLst.minLength(aadhaarnumber, this.minAadharLenght, "Aadhaar Number")) {
        vSts = false;
      }
      else if (!this.vldChkLst.validAadhar(aadhaarnumber)) {
        vSts = false;
      }
      else {
        vSts = true;
      }
    }else if(isVisible == 1){
      if (!this.vldChkLst.blankCheck(mobilenumber, "Mobile Number")) {
        vSts = false;
      }
      else if (!this.vldChkLst.maxLength(mobilenumber, this.maxLghMob, "Mobile Number")) {
        vSts = false;
      }
      else if (!this.vldChkLst.minLength(mobilenumber, this.minLghMob, "Mobile Number")) {
        vSts = false;
      }
      else if (!this.vldChkLst.validMob(mobilenumber)) {
        vSts = false;
      }
      else {
        vSts = true;
      }
    }
    else{
      vSts = false;
      Swal.fire({
        icon: 'error',
        text: 'Select User Type'
      });
    }

    if (!vSts) {
      return vSts;
    }
    else {
      this.loading = true;
      let param = {
        "aadhaarnumber": this.aadharNoInput,
        "mobilenumber": this.mobileNoInput,
        "isVisible": this.isVisible
      }
      this.loginService.farmerLoginCheckWithOtpSend(param).subscribe(response => {    
      let respData =this.encDec.decrypt(response.RESPONSE_DATA);  
      // let respToken = response.RESPONSE_TOKEN;
      // let respKey = response.RESPONSE_KEY;
      // let verificationKey = CryptoJS.SHA256(respData).toString();
      // let verifyToken = CryptoJS.HmacSHA256(respData, environment.apiHashingKey).toString();
       let res : any = { 'status': 0, 'result': {} };
      // if(respKey == verificationKey){
      //   if (respToken == verifyToken) {
           res = JSON.parse(respData);
          if(res.result.intIsCheckLogin>0){
            this.loading = false;
            Swal.fire({
              icon: 'question',
              text: 'Oops! It seems you have already logged in another device.Please click on logout below to continue.',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Logout from all devices!'
            }).then((result) => {
              if (result.isConfirmed) {
                this.loading = true;
                let regParam = {
                  "aadhaar_no": this.aadharNoInput,
                  "mobile_no": this.mobileNoInput,
                };
                this.loginService.isLogoutCheck(regParam).subscribe(res => {
                  if(res.status == 200){
                    this.loading = false;
                    Swal.fire({
                      icon: 'success',
                      text: 'Logged out Successfully'
                     }).then(function () {
                      window.location.reload();
                    });
                  }
                  else{
                    Swal.fire({
                      icon: 'error',
                      text: 'Something went wrong,Please try again later!'
                     }).then(function () {
                      window.location.reload();
                    });
                  }
                })
                // Swal.fire({
                //   icon: 'success',
                //   text: 'Logout Successfully'
                //  }).then(function () {
                //   window.location.reload();
                // });
              }
            });
          }else{
            if (res.status == 1) {
              this.loading = false;
              localStorage.setItem('authkey', res.result.authorization);
              this.timeLeft = 300;
              this.startTimer();
              this.open(this.someModalRef);
              this.enctext = res.result.enctext;
              this.result = res.result;
              this.mobileNo = this.result.mobile_No
            } else {
              this.loading = false;
              this.textOTP = null;
              Swal.fire({
                icon: 'error',
                text: res.msg
              });
            }
  
          }
  
        //}}
      },
        error => {

        });
    }
  }
/*
* Function name: verifyOTP
* description :  This method for verify OTP
* created by  : Bibhuti Bhusan Sahoo
* created on  : 20Jun2022
* return type : string
*/
  verifyOTP() {
    let textOTP = this.textOTP;
    let vSts = true;
    let otpTime = this.timeLeft;
    let enctext = this.enctext;
    if (!this.vldChkLst.blankCheck(textOTP, "Otp")) {
      vSts = false;
      this.textOTP = null;
    }
    else if (otpTime === 0) {
      this.textOTP = null;
      Swal.fire({
        icon: 'error',
        text: 'OTP expired, Click resend to get OTP'
      });
      vSts = false;
    }
    else {
      vSts = true;
    }


    if (!vSts) {
      return vSts;
    }
    else {
      this.loading = true;
      let regParam = {

        "otp": textOTP,
        "enctext": enctext,
        "profileId":this.result.intProfileId,
        "mobileNo": this.result.mobileNo
      };
      this.loginService.verifyotp(regParam).subscribe(response => {
        let respData = response.RESPONSE_DATA;
        let respToken = response.RESPONSE_TOKEN;
        let verifyToken = CryptoJS.HmacSHA256(respData, environment.apiHashingKey).toString();
        let res : any = { 'status': 0, 'result': {} };
        if (respToken == verifyToken) {
          res = JSON.parse(atob(respData));
          if (res.status == 1) {
            this.loading = false;
            this.modalService.dismissAll();
            let userInfo = {
              "userName": this.result.vchApplicantName,
              "userId": this.result.intProfileId,
              "aadharNo": this.result.aadharNo,
              "mobileNo": this.result.mobileNo
            }
            localStorage.setItem("userInfo", JSON.stringify(userInfo));

            let userSesnArr = {};
            userSesnArr["USER_SESSION"] = "access_token";
            userSesnArr["USER_ID"] = this.result.intProfileId;
            userSesnArr["USER_NAME_PRFX"] = this.result.vchApplicantPrefix;
            userSesnArr["USER_FULL_NAME"] = this.result.vchApplicantName;
            userSesnArr["USER_PROF_PIC"] = this.result.vchProfilePic;
            userSesnArr["MOBILE_REQUEST"] = false;
            userSesnArr["USER_MOBILE"] = this.result.mobileNo;
            userSesnArr["USER_SOURCE"] = this.result.intApplicantSource;
            userSesnArr["USER_AADHAR"] = this.result.aadharNo;
            userSesnArr["USER_BANK_NAME"] = this.result.vchBankName;
            userSesnArr["USER_BANK_BRANCH"] = this.result.vchBankBranchName;
            userSesnArr["USER_IFSC_CODE"] = this.result.vchBankIfscCode;
            userSesnArr["USER_BANK_ACC_NO"] = this.result.vchBankAccNo;
            userSesnArr["USER_BANK_ACC_HOLDER_NAME"] = this.result.vchBankAccHolderName;
            userSesnArr["USER_DOB"] = this.result.dteDateOfBirth;
            userSesnArr["USER_CATEGORY"] = this.result.tinCaste;
            userSesnArr["USER_ADDRESS"] = this.result.txtAddress;
            userSesnArr["USER_MARITAL_STATUS"] = this.result.tinMomstatus;
            userSesnArr["USER_DISTRICT_ID"] = this.result.intDistrictId;
            userSesnArr["USER_BLOCK_ID"] = this.result.intBlockId;
            userSesnArr["USER_GP_ID"] = this.result.intGpId;
            userSesnArr["USER_VILLAGE_ID"] = this.result.intVillageId;
            userSesnArr["USER_DISTRICT_NAME"] = this.result.vchDistrict;
            userSesnArr["USER_BLOCK_NAME"] = this.result.vchBlock;
            userSesnArr["USER_GP_NAME"] = this.result.vchGp;
            userSesnArr["USER_VILLAGE_NAME"] = this.result.vchVillage;
            userSesnArr["USER_GENDER"] = this.result.chrGender;
            // if(this.result.intApplicantSource == 2){
            //   let krushakInfo = this.krushakOdishaLogin(this.result.aadharNo);
            // }
            sessionStorage.setItem('FFS_SESSION', JSON.stringify(userSesnArr));
            if(this.returnUrl == 'ApplyForLoan')
              this.router.navigateByUrl('/citizen-portal/scheme-directorate');
            else if(this.returnUrl == 'LoanProduct')
              this.router.navigateByUrl('/home/loan-product');
            else
              this.router.navigateByUrl('/citizen-portal/dashboard');
          }
          else {
            this.loading = false;
            this.textOTP = null;
            Swal.fire({
              icon: 'error',
              html: res.msg
            });
          }
        }
        else {
          this.loading = false;
          this.textOTP = null;
          Swal.fire({
            icon: 'error',
            text: 'Verification Failed!'
          });
        }
      });

    }
  }

  /**
   * Function name: open
   * description :  This method is for open modal
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 20Jun2022
   */
  open(content: any) {
    this.modalService.open(content, { size: 'md', backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }
  /**
   * Function name: resendOtp
   * description :  This method is for resending OTP
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 20Jun2022
   */
  resendOtp() {
    let textOTP = this.textOTP;
    this.timeLeft = 120;
    this.startTimer();
    let mobileNo = this.result.mobileNo;
    let profId  = this.result.intProfileId
    this.textOTP = null;
    let regParam = {
      "mobileNo": mobileNo,
      "profileId": profId
    };
    this.loading = true;
    this.loginService.sendotp(regParam).subscribe(response => {
      let respData = response.RESPONSE_DATA;
      let respToken = response.RESPONSE_TOKEN;
      let verifyToken = CryptoJS.HmacSHA256(respData, environment.apiHashingKey).toString();
      let res : any = { 'status': 0, 'result': {} };
      if (respToken == verifyToken) {
        res = JSON.parse(atob(respData));
        if (res.status == 1) {
          this.loading = false;
          this.enctext = res.result.enctext;
        }
        else if(res.status == 0){
          console.log(res);
          this.loading = false;
          Swal.fire({
            icon: 'error',
            text: res.msg
          });
          this.modalService.dismissAll();
        }
        else {
          this.loading = false;
        }
      }else {
        this.loading = false;
      }
    });
  }
/********************************************
Function  Name     	: changeLang
Description			    : This function is For changing language
Created By    		  : Bindurekha Nayak
Created On    		  : 02-Oct-2022
********************************************/
  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    window.location.reload();
  }
/**
 * Function name: doLogout
 * description : This method is to logout user
 * created by  : Bindurekha Nayak
 * created on  : 20Dec2022
 */
  doLogout() {
    let regParam = {
      "aadhaar_no": this.aadharNoInput,
      "mobile_no": this.mobileNoInput,
    };

    this.loginService.isLogoutCheck(regParam).subscribe(res => {
    })
   // this.loginService.logoutLoginCheck();
  }
}
