<div class="loginbody">
  <div class="loginform">
    <div class="language-change ms-auto">
      <select class="form-select" #langSelect (change)="changeLang(langSelect.value)">
        <option *ngFor="let lang of translate.getLangs()" [value]="lang"
            [selected]="lang === translate.currentLang">{{ lang | translate}}</option>
    </select>
    </div>

    <div class="login-header">
      <div class="d-flex align-items-center">
        <img src="{{fileUrl}}k-odisha.png" alt="SAFAL">
        <div class="ms-2">
        </div>
      </div>
    </div>
    <h4 class="welcome mt-4">
      {{ 'Register Here' | translate}}
    </h4>

    <div class="login-inputs mt-2">
      <div class="row mb-4">
        <div class="col-sm-12">
          <div class="showDiv mt-4">
            <div class="input-group flex-nowrap mb-3">
              <span class="input-group-text"><em class="icon-koaadhaar aadhaar-icon"></em></span>
              <input type="text" class="form-control" placeholder="{{ 'Enter Aadhaar No.' | translate}}" [(ngModel)]="intAadharNo"
              [maxlength]="maxAadharLenght" [minlength]="minAadharLength" (keypress)="vldChkLst.isNumberKey($event)" maxlength="12">
            </div>
            <div class="input-group flex-nowrap  mb-3">
              <span class="input-group-text"><em class="icon-user-alt-solid"></em><font color="red">*</font></span>
              <input type="text" class="form-control" placeholder="{{ 'Enter Your Name.' | translate}}" id="txtApplicantName" [(ngModel)]="applicantName"
              [maxlength]="maxLghNm" [minlength]="minLghNm" (keypress)="vldChkLst.isCharKey($event)">
            </div>
            <div class="input-group flex-nowrap  mb-3">
              <span class="input-group-text"><em class="icon-mobile-alt-solid"></em><font color="red">*</font></span>
              <input type="text" class="form-control" placeholder="{{ 'Enter Mobile No.' | translate}}" [(ngModel)]="mobileNo" [maxlength]="maxLghMob" [minlength]="minLghMob" (keypress)="vldChkLst.isNumberKey($event)" maxlength="10">
            </div>
            <div class="input-group flex-nowrap  mb-3">
              <span class="input-group-text"><em class="icon-envelope-solid"></em></span>
              <input type="text" class="form-control" placeholder="{{ 'Enter Email ID' | translate}}" id="txtEmail" [(ngModel)]="emailId" [maxlength]="maxLghEmail" [minlength]="minLghEmail">
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-4">
          <a href="javascript:void(0)" class="btn btn-warning d-block" (click)="getOTP();"> {{ 'Register' | translate}}</a>
        </div>
        <div class="col-sm-8 d-flex align-items-center justify-content-end">
          <a routerLink="../home" class="d-block text-right"><em class="icon-home-solid me-1"></em> {{ 'Back to Home' | translate}} </a>
        </div>
      </div>


    </div>
    <p class="mb-0 text-center mt-2" style="font-size: .8rem; color:#8d8d8d;">© SAFAL, All rights reserved</p>
  </div>
</div>

<div class="loader" *ngIf="loading">
  <div class="loader-item"></div>
  <p>Loading...</p>
</div>

<ng-template #someModal id="someModal" let-modal>

  <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Verify Phone Number</h4>
          <button type="button" #closebutton class="close" data-dismiss="modal" aria-label="Close"
                  (click)="modal.dismiss('Cross click')">
                  <em class="icon-times-solid"></em>
          </button>
  </div>
  <div class="modal-body">

          <div class="form-row align-items-center">
              <div class="row">
                  <div class="col-4">
                      <label class="mt-2" for="txtOtp"><span class="mandatory">*</span> Enter OTP</label>

                  </div>
                  <div class="col-8">
                      <div class="form-group">
                          <input type="password" class="form-control" id="textOTP" [(ngModel)]="textOTP"
                          [maxlength]="maxLghotp" [minlength]="minLghotp" (keypress)="vldChkLst.isNumberKey($event)">
                          <p class="mb-0 "><strong class="text-danger">{{timeLeft}}</strong> Seconds Left....</p>
                      </div>
                  </div>
              </div>

              <div class="row">
                  <div class="col-12 mb-4">
                      <div class="text-danger" style="font-size: smaller;">* OTP has been sent to the registered mobile number.</div>
                      <div class="text-danger" style="font-size: smaller;" *ngIf="resend == 1">* OTP has been resent to the registered mobile number.</div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-4"></div>
                  <div class="col-8">
                      <button  type="submit"  class="btn btn-sm btn-success" (click)="verifyOTP();" >Verify</button>
                      <button type="button"  class="btn btn-sm btn-warning" (click)="resendOtp()" [disabled]="!editable" mdbWavesEffect>Resend OTP</button>
                  </div>
              </div>

          </div>
          <input type="hidden" class="form-control" name="txnId" id="txnId" value="{{txnId}}">
          <input type="hidden" class="form-control" name="uid" id="uid" value="{{uid}}">

  </div>

</ng-template>
