/* **************************************************
  Service Name   : Auth Token service
  Description : This service will add authorization to all the http request.
  Created By  : Bibhuti Bhusan Sahoo
  Created On  : 07Aug2023
 ******************************************* */

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthTokenInterceptorInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToekn = (localStorage.authkey != undefined) ? localStorage.getItem('authkey'): 'Not found';
    //const accessToekn = 'Test';
    const headers:any = {'Accept': 'application/json'};

     //console.log('===============>', req.headers.has('Content-Type'), req.url);
    if (!req.headers.has('Content-Type')) {
      headers['Content-Type'] = 'application/json';
    } else {
      req = req.clone({headers: req.headers.delete('Content-Type')});
    }   

    if (!req.headers.has('Authorization')) {
      headers['Authorization'] = 'Bearer '+accessToekn;
    }

    const dupReq = req.clone({
      setHeaders: headers
    });

    

    return <any>next.handle(dupReq);
  }
}
