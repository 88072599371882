import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {

  constructor() { }

  private key: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(environment.apiHashingKey);


  encText(plainText) {
    let encKey = environment.encryptKey;
    let text = plainText;
    let iv = CryptoJS.enc.Hex.parse(environment.encryptIV);
    return btoa(CryptoJS.AES.encrypt(text, encKey, { iv: iv }).toString());
  }

  decText(encryptedText) {
    encryptedText = atob(encryptedText);
    let encKey = environment.encryptKey;
    let iv = CryptoJS.enc.Hex.parse(environment.encryptIV);
    var decryptText = CryptoJS.AES.decrypt(encryptedText, encKey, { iv: iv });
    return decryptText.toString(CryptoJS.enc.Utf8);
  }

  encrypt(data: string): string {
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(data, this.key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  }

  decrypt(encryptedData: string): string {
    const parts = atob(encryptedData).split('::');
    //console.log(parts);  
    const encrypted = parts[0];
    const iv = CryptoJS.enc.Base64.parse(parts[1]);
  
    const decrypted = CryptoJS.AES.decrypt(encrypted, CryptoJS.enc.Utf8.parse(environment.apiHashingKey), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}
