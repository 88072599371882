<div class="loginbody">
  <div class="loginform">
<div class="language-change ms-auto">
  <select class="form-select" #langSelect (change)="changeLang(langSelect.value)">
    <option *ngFor="let lang of translate.getLangs()" [value]="lang"
        [selected]="lang === translate.currentLang">{{ lang | translate}}</option>
</select>
</div>

    <div class="login-header">
      <div class="d-flex align-items-center">
        <img src="{{fileUrl}}k-odisha.png" alt="Krushak Odisha" style="height: 72px;">
        <div class="ms-2">
         </div>
      </div>
    </div>
    <h4 class="welcome mt-4">
      <span class="d-block">{{'Hi' | translate}},</span>{{'Welcome to SAFAL' | translate}}
    </h4>
    <form>
      <div class="login-inputs mt-4">
        <div class="form-check form-check-inline">
          <input [value]="2" [(ngModel)]="isVisible" class="form-check-input" id="inlineRadio1" name="isVisible"
            type="radio" [checked]="!isSelected" />
          <label class="form-check-label" for="inlineRadio1">{{'Farmer' | translate}}</label>
        </div>
        <div class="form-check form-check-inline" *ngIf="!isDisabled">
          <input [value]="1" [(ngModel)]="isVisible" class="form-check-input" id="inlineRadio2"
            type="radio" [checked]="isSelected" />
          <label class="form-check-label" for="inlineRadio2">{{'Agri-Entrepreneur' | translate}}</label>
        </div>


        <div class="row mb-4">
          <div class="col-sm-12">
            <div class="hideDiv" *ngIf="isVisible == 2 || isVisible == 1">
              <p class="mt-3" *ngIf="isVisible == 2">
                {{'If you are already registered, then use your' | translate}}
                 <br> {{'aadhaar number to login.' | translate}}
              </p>
              <p class="mt-3" *ngIf="isVisible == 1">
                {{'If you are already registered, then use your' | translate}}
                 <br> {{'mobile number to login.' | translate}}
              </p>
              <ng-container *ngIf="isVisible == 2">
                <input type="text" placeholder="{{'Enter Aadhaar Number' | translate}}" class="form-control"
                [(ngModel)]="aadharNoInput" id="intAadharNo" autocomplete="off" (keypress)="vldChkLst.isNumberKey($event)"  maxlength="12" pattern="[0-9]">
              </ng-container>
              <ng-container *ngIf="isVisible == 1">
                <input type="text" placeholder="{{'Enter Mobile Number' | translate}}" class="form-control"
                [(ngModel)]="mobileNoInput" id="intMobileNo" (keypress)="vldChkLst.isNumberKey($event)" autocomplete="off"  maxlength="10">
              </ng-container>
            </div>
          </div>
        </div>


        <div class="row mb-4">
          <div class="col-sm-4" *ngIf="isVisible == 2 || isVisible == 1">
            <button class="btn btn-warning d-block" type="button" (click) = "loginForm();">{{'Login' | translate}}</button>
          </div>
          <div class="col-sm-8 d-flex align-items-center justify-content-end">
            <a routerLink="../home" class="d-block text-right"><em class="icon-home-solid me-1"></em>{{'Back to Home' | translate}}  </a>
          </div>
        </div>
        <div class="row " *ngIf="isVisible == 2">
          <div class="col-sm-12">
            <p class="">{{'Not registered yet ?' | translate}} <a href="{{koWebsiteUrl}}"
              target="_blank"><strong> {{'Register at Krushak Odisha.' | translate}}</strong></a></p>
          </div>
        </div>
        <div class="row " *ngIf="isVisible == 1">
          <div class="col-sm-12">
            <p class="">{{'Not registered yet ?' | translate}} <a routerLink="/Newregister"><strong>{{'Register Here.' | translate}}  </strong></a></p>
          </div>
        </div>


      </div>
    </form>
    <p class="mb-0 text-center mt-2" style="font-size: .8rem; color:#8d8d8d;">© SAFAL, All rights reserved</p>
  </div>



</div>

<div class="loader" *ngIf="loading">
  <div class="loader-item"></div>
  <p>Loading...</p>
</div>

<ng-template #someModal id="someModal" let-modal>

  <div class="modal-header">
    <div class="col-md-11"><h4 class="modal-title" id="modal-basic-title">{{'Verify Phone Number' | translate}}</h4></div>
    <div class="col-md-1 text-right"><button type="button" #closebutton class="close" data-dismiss="modal" aria-label="Close"
                  (click)="modal.dismiss('Cross click')">
                  <em class="icon-times-solid"></em>
          </button></div>
          
          
  </div>
  <div class="modal-body">

          <div class="form-row align-items-center">
              <div class="row">
                  <div class="col-4">
                      <label class="mt-2" for="txtOtp"><span class="mandatory">*</span> {{'Enter OTP' | translate}}</label>

                  </div>
                  <div class="col-8">
                      <div class="form-group">
                          <input type="password" class="form-control" id="textOTP" [(ngModel)]="textOTP"
                          [maxlength]="maxLghotp" [minlength]="minLghotp" (keypress)="vldChkLst.isNumberKey($event)">
                          <p class="mb-0 "><strong class="text-danger">{{timeLeft}}</strong> {{'Seconds Left....' | translate}}</p>
                      </div>
                  </div>
              </div>

              <div class="row">
                  <div class="col-12 mb-4">
                      <div class="text-danger" style="font-size: smaller;">{{'* OTP has been sent to the Krushak Odisha registered mobile number ' | translate}}({{mobileNo}}) *</div>
                      <div class="text-danger" style="font-size: smaller;" *ngIf="resend == 1">{{'* OTP has been sent to the Krushak Odisha registered mobile number ' | translate}}({{mobileNo}}) *</div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-4"></div>
                  <div class="col-8">
                      <button  type="submit"  class="btn btn-sm btn-success me-2" (click)="verifyOTP();" >{{'Verify' | translate}}</button>
                      <button type="button"  class="btn btn-sm btn-warning" (click)="resendOtp()" [disabled]="!editable" mdbWavesEffect>{{'Resend OTP' | translate}}</button>
                  </div>
              </div>

          </div>
          <input type="hidden" class="form-control" name="txnId" id="txnId" value="{{txnId}}">
          <input type="hidden" class="form-control" name="uid" id="uid" value="{{uid}}">

  </div>

</ng-template>
