import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class CitizenAuthService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'text/html',
      'Authorization': environment.my_bearer_auth,
    })
  };
  isAuthenticate = false;
  siteURL = environment.siteURL;

  login(params): Observable<any> {
    let serviceURL = environment.serviceURL + 'userLogin';
    let loginResponse = this.http.post(serviceURL, params);
    return loginResponse;
  }

  aadharVerify(params): Observable<any> {
    let serviceURL = environment.serviceURL + 'generateAadhaarOtpVerify';
    let regRes = this.http.post(serviceURL, params);
    return regRes;
  }
  reVerifyAadhaarOtp(params): Observable<any> {
    let serviceURL = environment.serviceURL + 'reVerifyAadhaarOtp';
    let regRes = this.http.post(serviceURL, params);
    return regRes;
  }
  verifyAadhaarOtp(params): Observable<any> {
    let serviceURL = environment.serviceURL + 'verifyAadhaarOtp';
    let regRes = this.http.post(serviceURL, params);
    return regRes;
  }
  sendotp(params): Observable<any> {
    let requestParam = btoa(JSON.stringify(params));
		
    let requestToken = CryptoJS.HmacSHA256(requestParam, environment.apiHashingKey).toString();
		//console.log(requestToken);
    let reqData = { 'REQUEST_DATA': requestParam, 'REQUEST_TOKEN': requestToken };
    let serviceURL = environment.serviceURL + 'sendotp';
    let regRes = this.http.post(serviceURL, reqData);
    return regRes;
  }
  verifyotp(params): Observable<any> {
    let requestParam = btoa(JSON.stringify(params));
		
    let requestToken = CryptoJS.HmacSHA256(requestParam, environment.apiHashingKey).toString();
		//console.log(requestToken);
    let reqData = { 'REQUEST_DATA': requestParam, 'REQUEST_TOKEN': requestToken };
    let serviceURL = environment.serviceURL + 'verifyotp';
    let regRes = this.http.post(serviceURL, reqData);
    return regRes;
  }

  register(params:any, key?:any): Observable<any> {
    let serviceURL = environment.serviceURL + 'userRegistration';
    if(key){
      let reqData = { 'REQUEST_DATA': params, 'REQUEST_KEY': key }
      var regRes = this.http.post(serviceURL, reqData);
    } else {
      var regRes = this.http.post(serviceURL, params);
    }
    return regRes;
  }

  public isLoggedIn() {
    return sessionStorage.getItem('FFS_SESSION') !== null;
  }

  public logout() {
    sessionStorage.removeItem('FFS_SESSION');
    sessionStorage.removeItem('sourceType');
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }

  public getCaptcha() {
    let serviceURL = environment.serviceURL + 'getCaptcha';
    let loginResponse = this.http.post(serviceURL, null);
    return loginResponse;
  }

  mobilelogin(params): Observable<any> {
    let serviceURL = environment.serviceURL + 'farmerMobileLogin';
    let loginResponse = this.http.post(serviceURL, params);
    return loginResponse;
  }

  krushakLogin(params): Observable<any>{
    let reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'SAMEORIGIN',
        'Authorization': "abg123hymf567frebdt435hdngti789mchcbe123ncbcbf8680fsrebbcjdyteue35346482gdgdvcbd0987654321" 
    })
		let serviceURL = environment.krushakOdishaUrl + 'getRegisteredFarmerData';
		let regRes = this.http.post(serviceURL, params, { headers: reqHeader });
		return regRes;
	}

  decryptText(params): Observable<any> {
    let serviceURL = environment.serviceURL + 'decryptText';
    let decryptResponse = this.http.post(serviceURL, params);
    return decryptResponse;
  }
  encryptText(params): Observable<any> {
    let serviceURL = environment.serviceURL + 'encryptText';
    let decryptResponse = this.http.post(serviceURL, params);
    return decryptResponse;
  }
  isLogoutCheck(params): Observable<any> {
    let serviceURL = environment.serviceURL + 'logoutIsCheck';
    let regRes = this.http.post(serviceURL, params);
    return regRes;
  }


  constructor(private http: HttpClient, private router: Router) { }
}
