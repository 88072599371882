<div class="error-section">
    <img src="assets/images/error.png" alt="Error">
    <div class="error-section__content">
       <h1> <span> 404 </span> Page Not Found </h1>
       <p>Sorry, but we can't find what you were looking for right now.</p>
       <p>The content may have been removed, or is temporarily unavailable.</p>
       <a href="home/">  Home </a>
    </div>
 </div>

