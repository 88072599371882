/* **************************************************
  Component Name   : New Register Component
  Description : This component is used for Agri Enterprenure Registration  process
  Created By  : Bibhuti Bhusan Sahoo
  Created On  : 20Jun2022
 ******************************************* */

import { ValidatorchklistService } from 'src/app/validatorchklist.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CitizenAuthService } from '../citizen-portal/service-api/citizen-auth.service';
import { environment } from '../../environments/environment';
import { NgbModal, NgbTooltip, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EncryptDecryptService } from 'src/app/encrypt-decrypt.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-newregister',
  templateUrl: './newregister.component.html',
  styleUrls: ['./newregister.component.css']
})
export class NewregisterComponent implements OnInit {
  maxAadharLenght = 12;
  minAadharLength = 12;
  intAadharNo: any = '';
  applicantName = '';
  emailId = '';
  mobileNo = '';
  password = '';
  confirmPwd = '';
  maxLghNm = 100;
  minLghNm = 5;
  maxLghEmail = 50;
  minLghEmail = 10;
  maxLghMob = 10;
  minLghMob = 10;
  maxLghPwd = 15;
  minLghPwd = 8;
  loading = false;
  textOTP = '';
  sendOTP = '';
  enctext = '';
  timeLeft: number = 120;
  interval;
  public editable = false;
  fileUrl = environment.ccpHomeUrl;
  language: any = 'English';
  @ViewChild('someModal') someModalRef: ElementRef;

  constructor(
    private router: Router,
    private encDec: EncryptDecryptService,
    public vldChkLst: ValidatorchklistService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public authService: CitizenAuthService,
    public translate: TranslateService,
  ) { translate.addLangs(['English', 'Odia']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|Odia/) ? browserLang : 'English');
      $('body').addClass(browserLang);
    } else {
      localStorage.setItem('locale', 'English');
      translate.setDefaultLang('English');
    }}

  ngOnInit(): void {
  }

  /**
   * Function name: getOTP
   * description :  This method for validate the user registration and send otp to given mobile no
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 23Jun2022
   */

  getOTP() {
    let namePrfx = '';
    let intAadharNo = this.intAadharNo;
    let emailId = this.emailId;
    let mobileNo = this.mobileNo;
    let password = this.password;
    let applicantName = this.applicantName;
    let confirmPwd = this.confirmPwd;

    let vSts = true;
    if (!this.vldChkLst.maxLength(intAadharNo, this.maxAadharLenght, "Aadhaar Number")) {
      vSts = false;
    }
    else if (!this.vldChkLst.minLength(intAadharNo, this.minAadharLength, "Aadhaar Number")) {
      vSts = false;
    }
    else if (!this.vldChkLst.validAadhar(intAadharNo)) {
      vSts = false;
    }
    else if (!this.vldChkLst.blankCheck(applicantName, "Applicant Name")) {
      vSts = false;
    }
    else if (!this.vldChkLst.blankCheck(mobileNo, "Mobile Number")) {
      vSts = false;
    }
    else if (!this.vldChkLst.validMob(mobileNo)) {
      vSts = false;
    }
    else if (!this.vldChkLst.maxLength(mobileNo, this.maxLghMob, "Mobile Number")) {
      vSts = false;
    }
    else if (!this.vldChkLst.minLength(mobileNo, this.minLghMob, "Mobile Number")) {
      vSts = false;
    }
    else if (!this.vldChkLst.validEmail(emailId)) {
      vSts = false;
    }
    else if (!this.vldChkLst.maxLength(emailId, this.maxLghNm, "Email Id")) {
      vSts = false;
    }
    else if (!this.vldChkLst.minLength(emailId, this.minLghNm, "Email Id")) {
      vSts = false;
    }
    else {
      vSts = true;
    }
    if (!vSts) {
      return vSts;
    }
    else {
      this.timeLeft = 120;
      this.loading = true;
      // this.startTimer();
      // this.open(this.someModalRef);
      let regParam = {

        "mobileNo": mobileNo,

      };
      this.authService.sendotp(regParam).subscribe(response => {
      let respData = response.RESPONSE_DATA;
      let respToken = response.RESPONSE_TOKEN;
      let verifyToken = CryptoJS.HmacSHA256(respData, environment.apiHashingKey).toString();
      let res : any = { 'status': 0, 'result': {} };
      if (respToken == verifyToken) {
        res = JSON.parse(atob(respData));
        if (res.status == 1) {
          this.loading = false;
          this.startTimer();
          this.open(this.someModalRef);
          let result = res.result;
          this.enctext = result['enctext'];
          let profileId = result['profileId'];
          if(profileId > 0 || profileId != undefined){
            let encProfId = this.encDec.encText((profileId).toString());
            this.router.navigateByUrl('/citizen-portal/registration-confirmation/' + encProfId);
            this.loading = false;
          }
          this.loading = false;
          
        }
        else if(res.status == 0){
          this.loading = false;
          Swal.fire({
            icon: 'error',
            text: res.msg
          });
          this.modalService.dismissAll();
        }
        else {
          this.loading = false;
        }
      }
      else {
        this.loading = false;
      }
      });
    }
  }

  /**
   * Function name: verifyAadharFormat
   * description :  This method for verify Aadhar Format
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 23Jun2022
   */

  verifyAadharFormat() {
    if (this.vldChkLst.validAadhar(this.intAadharNo)) {
      Swal.fire({
        icon: 'success',
        text: 'Aadhar Format Verified'
      });
    }
  }

  /**
   * Function name: verifyOTP
   * description :  This method for verify OTP
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 23Jun2022
   */

  verifyOTP() {
    let textOTP = this.textOTP;
    let vSts = true;
    let otpTime = this.timeLeft;
    let enctext = this.enctext;
    if (!this.vldChkLst.blankCheck(textOTP, "Otp")) {
      vSts = false;
      this.textOTP = null;
    }
    else if (otpTime === 0) {
      this.textOTP = null;
      Swal.fire({
        icon: 'error',
        text: 'OTP expired, Click resend to get OTP'
      });
      vSts = false;
    }
    else {
      vSts = true;
    }


    if (!vSts) {
      return vSts;
    }
    else {
      this.loading = true;
      let regParam = {

        "otp": textOTP,
        "enctext": enctext,
        "mobileNo":this.mobileNo
      };
      this.authService.verifyotp(regParam).subscribe(response => {
        let respData = response.RESPONSE_DATA;
        let respToken = response.RESPONSE_TOKEN;
        let verifyToken = CryptoJS.HmacSHA256(respData, environment.apiHashingKey).toString();
        let res : any = { 'status': 0, 'result': {} };
        if (respToken == verifyToken) {
          res = JSON.parse(atob(respData));
          if (res.status == 1) {
            this.loading = false;
            this.modalService.dismissAll();
            this.register()
          }
          else {
            this.loading = false;
            this.textOTP = null;
            Swal.fire({
              icon: 'error',
              html: res.msg
            });
          }
        }
        else {
          this.loading = false;
          this.textOTP = null;
          Swal.fire({
            icon: 'error',
            text: 'Verification failed!'
          });
        }
      });

    }
  }

  /**
   * Function name: resendOtp
   * description :  This method for resend OTP
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 23Jun2022
   */

  resendOtp() {
    this.loading = true;
    let textOTP = this.textOTP;
    // this.timeLeft = 120;
    // this.startTimer();
    let mobileNo = this.mobileNo;
    this.textOTP = null;
    let regParam = {
      "mobileNo": mobileNo,
    };
    this.authService.sendotp(regParam).subscribe(response => {
      let respData = response.RESPONSE_DATA;
      let respToken = response.RESPONSE_TOKEN;
      let verifyToken = CryptoJS.HmacSHA256(respData, environment.apiHashingKey).toString();
      let res : any = { 'status': 0, 'result': {} };
      if (respToken == verifyToken) {
        res = JSON.parse(atob(respData));
        if (res.status == 1) {
          this.loading = false;
          this.timeLeft = 120;
          this.startTimer();
          let result = res.result;
          this.enctext = result['enctext'];
        }
        else if(res.status == 0){
          this.loading = false;
          Swal.fire({
            icon: 'error',
            text: res.msg
          });
          this.modalService.dismissAll();
        }
        else {
          this.loading = false;
        }
      }
      else {
        this.loading = false;
      }
    });
  }

  /**
   * Function name: register
   * description :  This method for register the non-farmer user
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 23Jun2022
   */

  register() {
    let namePrfx = '';
    let applicantName = this.applicantName;
    let emailId = this.emailId;
    let mobileNo = this.mobileNo;
    let password = this.password;
    let confirmPwd = this.confirmPwd;
    let intAadharNo = this.intAadharNo;


    let regParam = {
      "namePrfx": namePrfx,
      "fullName": applicantName,
      "aadhaarnumber": intAadharNo,
      "mobileNo": mobileNo,
      "emailId": emailId,
      "password": password,
      "cPassword": confirmPwd,
      "sourceType": 1,
    };
    let requestParam = btoa(btoa(JSON.stringify(regParam)));
		let requestKey = CryptoJS.SHA256(requestParam).toString();
    this.loading = true;
    this.authService.register(requestParam, requestKey).subscribe(res => {
      if (res.status == 1) {
        let result = res.result;
        let profileId = result['profileId'];
        let encProfId = this.encDec.encText((profileId).toString());
        this.loading = false;
        let userInfo = {
          "userName": result['applicantName'],
          "userId": result['profileId'],
          "aadharNo": result['aadhaarNumber'],
          "mobileNo": result['mobileNo']
        }
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem('authkey', result['authorization']);

        let userSesnArr = {};
        userSesnArr["USER_SESSION"] = "access_token";
        userSesnArr["USER_ID"] = result['profileId'];
        userSesnArr["USER_FULL_NAME"] = result['applicantName'];
        userSesnArr["MOBILE_REQUEST"] = false;
        userSesnArr["USER_MOBILE"] = result['mobileNo'];
        userSesnArr["USER_SOURCE"] = result['intApplicantSource'];
        userSesnArr["USER_AADHAR"] = result['aadhaarNumber'];
        userSesnArr["USER_PROF_PIC"] = result['vchProfileImage'];
        sessionStorage.setItem('FFS_SESSION', JSON.stringify(userSesnArr));
        Swal.fire({
          icon: 'success',
          html: 'Congratulations !<br>You have been successfully registerd on <strong>SAFAL</strong>.'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/citizen-portal/dashboard']);
         }
       });
      }
      else {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          text: res.msg
        });

      }
    });
  }

  /**
   * Function name: startTimer
   * description :  This method for start the otp timer
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 23Jun2022
   */

  startTimer() {
    if(this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.editable = false;
      } else {
        this.timeLeft = 0;
        this.editable = true;
      }
    }, 1000)
  }

  /**
   * Function name: open
   * description :  This method for open modal
   * created by  : Bibhuti Bhusan Sahoo
   * created on  : 23Jun2022
   */

  open(content: any) {
    this.modalService.open(content, { size: 'md', backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }
  /**
 * Function name: changeLang
 * description :  This method for changing language
 * created by  : Bindurekha Nayak
 * created on  : 232022
 */
  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    window.location.reload();
  }

}
