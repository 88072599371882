import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { EncryptionService } from '../encryption.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	constructor(private Http: HttpClient,private router: Router , private enc: EncryptionService) { }

	/*
	  ---------------------------------
	  Function Name : farmerLoginCheckWithOtpSend
	  Desc : Check whether aadhaar has register with mobile number and send the otp
	  Created By : Bibhuti Bhusan Sahoo
	  Created On :  14-06-2022
	  Return Type : send otp or mobile number is not registered
	  ---------------------------------
	  */
	farmerLoginCheckWithOtpSend(params: any): Observable<any> {
		// let requestParam = btoa(btoa(JSON.stringify(params)));
		 let requestParam = JSON.stringify(params);
		
		let requestKey = CryptoJS.SHA256(requestParam).toString();
    	let requestToken = CryptoJS.HmacSHA256(requestParam, environment.apiHashingKey).toString();


		requestParam = this.enc.encrypt(requestParam);

		//let reqData = { 'REQUEST_DATA': requestParam, 'REQUEST_TOKEN': requestToken, 'REQUEST_KEY': requestKey };
		let reqData = { 'REQUEST_DATA': requestParam};
    	
		let serviceUrl = environment.serviceURL + 'farmerLoginCheckWithOtpSend';
		let loginCheckInfoRes = this.Http.post(serviceUrl, reqData);
		return loginCheckInfoRes;
	}

	// SSO Login : Added By Banashri :: 25-Apr-2024

	ssoLogin(params: any): Observable<any> {
		let requestParam = btoa(JSON.stringify(params));
    	let requestToken = CryptoJS.HmacSHA256(requestParam, environment.apiHashingKey).toString();
    	let reqData = { 'REQUEST_DATA': requestParam, 'REQUEST_TOKEN': requestToken };
		let serviceUrl = environment.serviceURL + 'ssoLogin';
		let loginCheckInfoRes = this.Http.post(serviceUrl, reqData);
		return loginCheckInfoRes;
	}

	verifyotp(params): Observable<any> {
		let requestParam = btoa(JSON.stringify(params));
    	let requestToken = CryptoJS.HmacSHA256(requestParam, environment.apiHashingKey).toString();
    	let reqData = { 'REQUEST_DATA': requestParam, 'REQUEST_TOKEN': requestToken };
		let serviceURL = environment.serviceURL + 'verifyotp';
		let regRes = this.Http.post(serviceURL, reqData);
		return regRes;
	}
	public logout() {
		sessionStorage.removeItem('FFS_SESSION');
    	this.router.navigateByUrl('/home');
	}
	sendotp(params): Observable<any> {
		let requestParam = btoa(JSON.stringify(params));
    	let requestToken = CryptoJS.HmacSHA256(requestParam, environment.apiHashingKey).toString();
    	let reqData = { 'REQUEST_DATA': requestParam, 'REQUEST_TOKEN': requestToken };
		let serviceURL = environment.serviceURL + 'sendotp';
		let regRes = this.Http.post(serviceURL, reqData);
		return regRes;
	}
	isLogoutCheck(params): Observable<any> {
		let serviceURL = environment.serviceURL + 'logoutIsCheck';
		let regRes = this.Http.post(serviceURL, params);
		return regRes;
	}
	public logoutLoginCheck() {
		sessionStorage.removeItem('FFS_SESSION');
		sessionStorage.removeItem('sourceType');
		localStorage.removeItem('authkey');
		sessionStorage.clear();
		localStorage.clear();
		this.router.navigateByUrl('/login');
	}
	doLogoutCheckProcess(params): Observable<any> {
		let serviceURL = environment.serviceURL + 'logoutAllLoginCheck';
		let regRes = this.Http.post(serviceURL, params);
		return regRes;
	}
}
